import { useState } from 'react'
import styles from './index.module.css'
import register from '../../images/register.svg'
import apply from '../../images/apply.svg'
import collaborate from '../../images/collaborate.svg'
import enroll from '../../images/slides/slide8.png'
import QR2 from '../../images/QR2.png'
import QR3 from '../../images/QR3.png'


export default function EnrollMobile() {
    const [show, setShow] = useState(false)
    function onMouseEnter() {
        setShow(true)
    }
    function onMouseLeave() {
        setShow(false)
    }
    const QRStyle = {
        position: 'relative',
        width: '100%',
        height: '50px',
        visibility: show ? 'visible' : 'hidden'
    }
    return (
        <div className={styles.container}>
            <img className={styles.enroll} src={enroll} alt="enroll" />

            <div className={styles.info}>
                <img className={styles.svg} src={register} alt="register" />
                <div className={styles.title}>注册购票</div><br />
                <div>本次会议可凭 Github 地址<a href="https://wj.qq.com/s2/9727086/d7a7/" target="_blank" rel="noreferrer">免费注册</a></div>
            </div>

            <div className={styles.info}>
                <img className={styles.svg} src={apply} alt="apply" />
                <div className={styles.title}>嘉宾申请</div><br />
                <div>如果您希望与大家分享你的 Web3 知识
                    <br />
                    我们诚挚邀请您成为我们的
                    <a href="https://wj.qq.com/s2/9727086/d7a7/" target="_blank" rel="noreferrer">演讲嘉宾</a>
                </div>
            </div>

            <div className={styles.info}>
                <img className={styles.svg} src={collaborate} alt="collaborate" />
                <div className={styles.title}>合作咨询</div><br />
                <div>如果您希望与本大会合作或者有任何问题
                    <br />
                    欢迎<div style={{ display: "inline-block", color: "yellow" }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>联系我们</div>
                </div>
            </div>

            <div style={QRStyle} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <br />
                <div className={styles.qr}>
                    <img style={{width: 80}} src={QR2} alt="QR2" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img style={{width: 80}} src={QR3} alt="QR3" />
                </div>
            </div>
            <p className={styles.email}>web3@confluxnetwork.org</p>
        </div>
    )
}