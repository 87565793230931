import { useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import styles from './index.module.css'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import xhh from '../../images/redFlower.jpg'

export default function Sider() {
    let navigate = useNavigate()
    let enroll = 'enroll-pc'
    const [qr, setQr] = useState(false)

    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        enroll = 'enroll-mobile'
    }

    function goTo(dest) {
        navigate(`/${dest}`)
    }

    function miniProg() {
        setQr(!qr)
        console.log(qr)
    }

    const { SubMenu } = Menu
    return (
        <div className={styles.container}>
            <Menu mode="inline" theme="dark" defaultOpenKeys={['sider', 'claim', 'preheat']}>
                <SubMenu key="sider" icon={<MenuOutlined />} title="Menu">
                    <Menu.Item key="home" onClick={() => goTo('')}>首页</Menu.Item>
                    <Menu.Item key="partner" onClick={() => goTo('partner')}>参会机构</Menu.Item>
                    <Menu.Item key="enroll" onClick={() => goTo(enroll)}>如何参与</Menu.Item>
                    <Menu.Item key="schedule"><a href="https://web3.conflux.fun/Web3-Schedule.pdf" target="_blank" rel="noreferrer">会议流程</a></Menu.Item>
                    <SubMenu className="flower" key="claim" title="纪念NFT">
                        <Menu.Item key="web">
                            <a href="https://xhh.jugugu.cn/" target="_blank" rel="noreferrer">已安装Fluent钱包</a>
                        </Menu.Item>
                        <Menu.Item key="wechat" onClick={() => miniProg()}>小程序领取</Menu.Item>
                    </SubMenu>
                    <SubMenu className="preheat" key="preheat" title="预热活动">
                        {/* <Menu.Item key="3-18">
                            <a href="" target="_blank" rel="noreferrer">3-18 PreTalk1: NFT与CC0</a>
                            3-18 PreTalk1: NFT与CC0
                        </Menu.Item> */}
                        <Menu.Item key="3-27">
                            <a href="https://shimo.im/docs/UZZltUr4W6cvTuUJ/read" target="_blank" rel="noreferrer">3-27 PreTalk2: Web3就业指南</a>
                        </Menu.Item>
                    </SubMenu>
                </SubMenu>
            </Menu>

            {qr && createPortal(
                <div className={styles.qr}>
                        <img src={xhh} alt="xhh" />
                </div>
                , document.body
            )}
        </div>
    )
}