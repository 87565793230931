import { useState } from 'react'
import styles from './index.module.css'
import register from '../../images/register.svg'
import apply from '../../images/apply.svg'
import collaborate from '../../images/collaborate.svg'
import enroll from '../../images/slides/slide8.png'
import QR2 from '../../images/QR2.png'
import QR3 from '../../images/QR3.png'


export default function EnrollPc() {
    const [show, setShow] = useState(false)
    function onMouseEnter() {
        setShow(true)
    }
    function onMouseLeave() {
        setShow(false)
    }
    const QRStyle = {
        position: 'absolute',
        width: '350px',
        height: '150px',
        left: '45%',
        bottom: '0',
        right: '0',
        top: '65%',
        paddingTop: '30px',
        marginLeft: '220px',
        textAlign: 'center',
        visibility: show ? 'visible' : 'hidden'
    }
    return (
        <div>
            <main className={styles.container}>
                <img className={styles.enroll} src={enroll} alt="enroll" />

                <div className={`${styles.info} ${styles.register}`}>
                    <img className={styles.svg} src={register} alt="register" />
                    <p>注册购票</p><br />
                    <p>本次会议可凭 Github 地址<a href="https://wj.qq.com/s2/9727086/d7a7/" target="_blank" rel="noreferrer">免费注册</a></p>
                </div>
    
                <div className={`${styles.info} ${styles.apply}`}>
                    <img className={styles.svg} src={apply} alt="apply" />
                    <p>嘉宾申请</p>
                    <p>如果您希望与大家分享你的 Web3 知识，我们诚挚邀请您成为我们的<a href="https://wj.qq.com/s2/9727086/d7a7/" target="_blank" rel="noreferrer">演讲嘉宾</a></p>
                </div>

                <div className={`${styles.info} ${styles.collaborate}`}>
                    <img className={styles.svg} src={collaborate} alt="collaborate" />
                    <p>合作咨询</p>
                    <p>如果您希望与本大会合作或者有任何问题，欢迎<div style={{ display: "inline-block", color: "yellow" }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>联系我们</div></p>
                </div>
                
                <div style={QRStyle} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <br />
                    <img className={styles.qr} src={QR2} alt="QR2" />&nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={styles.qr} src={QR3} alt="QR3" />
                </div>

                <p className={styles.email}>web3@confluxnetwork.org</p>
            </main>
        </div>
    )
}