import topic from '../../images/slides/tbd.jpg'
import abstract from '../../images//slides/slide2.png'
import reference from '../../images/slides/slide3.png'
import capital from '../../images/slides/slide4.png'
import ownership from '../../images/slides/slide5.png'
import hotspot from '../../images/slides/slide6.png'
import styles from './index.module.css'


function Home() {
    return (
        <div className={styles.container}>
            <img src={topic} alt="topic" />
            <img src={abstract} alt="abstract" />
            <img src={reference} alt="reference" />
            <img src={capital} alt="capital" />
            <img src={ownership} alt="ownership" />
            <img src={hotspot} alt="hotspot" />
        </div>
    )
}


export default Home