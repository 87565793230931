import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Partner from './pages/Partner'
import EnrollPc from './pages/Pc'
import EnrollMobile from './pages/Mobile'
import Sider from './components/Sider'
// import Header from './components/Header'

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                {/* < Header /> */}
                <Sider />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="partner" element={<Partner />} />
                    <Route path="enroll-pc" element={<EnrollPc />} />
                    <Route path="enroll-mobile" element={<EnrollMobile />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
